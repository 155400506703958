// @ts-nocheck
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import PageHeader from 'Components/Shared/PageHeader/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import Dot from 'Components/Shared/Dot/Dot';
import UpdateDate from 'Components/Atoms/UpdateDate/VisionConfiguration';
import SkeletonList from 'Components/Atoms/SkeletonList/SkeletonList';
import ErrorComponent from 'Components/Atoms/ErrorComponent/ErrorComponent';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import { trackedEvents, trackedPages, roleConsts } from 'Utils/consts';
import TabsSelector from 'Components/Molecules/TabsSelector/index';
import List from 'Components/Shared/List/List';
import ListVisionConfiguration from 'Components/Molecules/ListVisionConfiguration/ListVisionConfiguration';
import { getHeadersWithTooltips } from 'Components/Molecules/HeadersWithTooltips/HeadersWithTooltips';
import { safeNormalizeString, safeToUpperCase } from 'Utils/safeMethods';
import ConfirmationModal from 'Components/Molecules/ConfirmationModal/ConfirmationModal';
import SnackbarTransactions from 'Components/Atoms/SnackbarTransactions/SnackbarTransactions';
import { StylableFormControlLabel, Checkbox } from 'Components/Atoms/FormControltems/styles';
import colorVariables from 'Utils/Styles/colorVariables';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';
import SearchInput from 'Components/Shared/SearchInput';
import { trackEvent } from 'Utils/analytics';
import { User } from 'Common/Helpers/AuthHelper';
import './VisionConfiguration.scss';

const VisionConfiguration = ({
  error,
  loading,
  rules,
  loadRules,
  updateRules,
  update,
  clearUpdateFailure,
  subMenuList,
  currentActiveSubMenu,
  setCurrentActiveSubMenu,
}) => {
  useLayoutEffect(() => {
    trackEvent(trackedEvents.pageView, { page: trackedPages.visibilityControl });
    if (currentActiveSubMenu == null) {
      setCurrentActiveSubMenu(0);
      loadRules(roleConsts.client);
    }
  }, []);

  const defaultVersionCode = '000';

  const defaultCheckedRules = {
    concluded: {
      checked: false,
      indeterminate: false,
    },
    late: {
      checked: false,
      indeterminate: false,
    },
    estimated: {
      checked: false,
      indeterminate: false,
    },
    visible: {
      checked: false,
      indeterminate: false,
    },
  };

  const orderTypeList = langValues.settingsVisionConfiguration.tabs.map((item, key) => ({
    id: key,
    label: item.text,
    value: item.key,
  }));

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [currentActiveModel, setCurrentActiveModel] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchString, setSearchString] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredCheckedItems, setFilteredCheckedItems] = useState([]);
  const [isFilteredItemsEmpty, setIsFilteredItemsEmpty] = useState(false);
  const [checkedRules, setCheckedRules] = useState(defaultCheckedRules);

  const currentMenu = subMenuList.find((item) => item.id === currentActiveSubMenu)?.value;

  const getModelVersionRules = () => {
    let modelVersionRules = [];
    rules.forEach((item) => {
      const hasItem = modelVersionRules.some(
        (element) =>
          element.modelCode === item.modelCode &&
          element.versionCode === item.versionCode &&
          element.orderType === item.orderType &&
          element.rule === item.rule
      );
      if (!hasItem) {
        const model = {
          ...item,
          versionCode: defaultVersionCode,
          versionName: null,
        };
        modelVersionRules.push(model);
      }
    });
    modelVersionRules = [...modelVersionRules, ...rules];
    return modelVersionRules;
  };

  const orderTypeKey = orderTypeList.find((item) => item.id === currentActiveTab).value;
  const orderTypeItems = getModelVersionRules().filter((item) => item.orderType === orderTypeKey);
  const checkedTypeItems = checkedItems.filter((item) => item.orderType === orderTypeKey);

  const currentItems = filteredItems.length > 0 || isFilteredItemsEmpty ? filteredItems : orderTypeItems;
  const currentCheckedItems =
    filteredItems.length > 0 || isFilteredItemsEmpty ? filteredCheckedItems : checkedTypeItems;

  const getNumberOfRules = () => {
    const singleModel = orderTypeItems[0];

    return orderTypeItems.filter(
      (item) => item.modelCode === singleModel.modelCode && item.versionCode === singleModel.versionCode
    ).length;
  };

  const getOrderTypeCheckedItems = (orderType, checkedToUpdate) => {
    const models = checkedToUpdate.filter(
      (item) => item.versionCode === defaultVersionCode && item.orderType === orderType
    );
    const versions = checkedToUpdate.filter(
      (item) => item.versionCode !== defaultVersionCode && item.orderType === orderType
    );
    const checked = [...versions];
    models.forEach((model) => {
      if (!versions.some((version) => version.modelCode === model.modelCode)) {
        checked.push(model);
      }
    });

    return checked;
  };

  const getAllCheckedItems = (checkedToUpdate) => {
    let allCheckedItems = [];
    orderTypeList.forEach((item) => {
      allCheckedItems = [...allCheckedItems, ...getOrderTypeCheckedItems(item.value, checkedToUpdate)];
    });
    return allCheckedItems;
  };

  const setNewCheckedRules = (checkedToUpdate) => {
    const allCheckedItems = getAllCheckedItems(checkedToUpdate);
    const newCheckedRules = { ...defaultCheckedRules };
    if (allCheckedItems.length > 0) {
      Object.keys(defaultCheckedRules).forEach((rule) => {
        const every = allCheckedItems.filter((item) => item.rule === rule).every((item) => item.conditional === true);
        if (every) {
          newCheckedRules[rule].checked = true;
          newCheckedRules[rule].indeterminate = false;
        } else {
          const some = allCheckedItems.filter((item) => item.rule === rule).some((item) => item.conditional === true);
          if (some) {
            newCheckedRules[rule].checked = false;
            newCheckedRules[rule].indeterminate = true;
          } else {
            newCheckedRules[rule].checked = false;
            newCheckedRules[rule].indeterminate = false;
          }
        }
      });
    }
    setCheckedRules(newCheckedRules);
  };

  const renderRulesCheckBoxes = () => {
    const handleCheckBoxChanging = (rule) => {
      const newCheckedRules = { ...checkedRules };
      newCheckedRules[rule].indeterminate = false;
      if (checkedRules[rule].checked) {
        newCheckedRules[rule].checked = false;
      } else {
        newCheckedRules[rule].checked = true;
      }
      setCheckedRules(newCheckedRules);
    };

    const selectedItemsExist = checkedItems.length > 0;
    const disabledCheckbox = selectedItemsExist ? '' : ' disabled';
    const formControlStyle = {
      root: {
        cursor: 'default',
      },
    };
    const FormControl = StylableFormControlLabel(selectedItemsExist ? null : formControlStyle);

    const renderRuleCheckBox = (rule, enabled) => (
      <Checkbox
        checked={checkedRules[rule].checked}
        indeterminate={checkedRules[rule].indeterminate}
        onChange={() => {
          if (enabled) {
            handleCheckBoxChanging(rule);
          }
        }}
      />
    );

    const renderTooltipText = (tooltipText) => (
      <div className="sideBarToolTip">
        <span>{tooltipText}</span>
      </div>
    );

    const renderDisabledRuleCheckBox = (rule) => (
      <CustomTooltip
        label={renderTooltipText(langValues.settingsVisionConfiguration.selectModelVersion)}
        placement="top"
        key={`label ${rule}`}
      >
        {renderRuleCheckBox(rule, false)}
      </CustomTooltip>
    );

    return (
      <div>
        {Object.keys(defaultCheckedRules).map((rule) => {
          if (rule === 'visible' && currentMenu !== 'ROLE_API_FULL_ACCESS') {
            return null;
          }
          return (
            <div className={`modalOneRule${disabledCheckbox} ${rule}`} key={rule}>
              <div className="modalOneRuleText">
                <FormControl
                  control={selectedItemsExist ? renderRuleCheckBox(rule, true) : renderDisabledRuleCheckBox(rule)}
                  label={<h6>{langValues.settingsVisionConfiguration.rules[rule].text}</h6>}
                />
              </div>
              <span className="modalOneRuleDescription">
                {langValues.settingsVisionConfiguration.rules[rule].description}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const getOrderTypeCheckedItemsLength = (orderType) =>
    getOrderTypeCheckedItems(orderType, checkedItems).length / getNumberOfRules();

  const renderOrderTypesCheckedItems = (item) => (
    <p className="orderTypeText" key={`sideBarOrderType ${item.value}`}>
      <span>
        <strong>{`${item.label}: `}</strong>
        {`${getOrderTypeCheckedItemsLength(item.value)}
         ${langValues.settingsVisionConfiguration.selectedItems}`}
      </span>
    </p>
  );

  const renderEditingOrderTypes = () => orderTypeList.map((item) => renderOrderTypesCheckedItems(item));

  const renderSideBarText = (subTitle, text) => (
    <div className="sideBarText" key={subTitle}>
      <p className="subTitle">{subTitle}</p>
      <span className="text">{text}</span>
    </div>
  );

  const renderSideBarContent = (contentValue) =>
    contentValue.map((item) => renderSideBarText(item.subTitle, item.text));

  const renderEditingSideBar = (
    <div className="sideBar editing">
      <div className="sideBarHeader">
        <h5 className="sideBarTitle">{langValues.settingsVisionConfiguration.sideBar.titleEditing}</h5>
        {renderEditingOrderTypes()}
      </div>
      <div className="sideBarBody">{renderRulesCheckBoxes()}</div>
    </div>
  );

  const renderNormalSideBar = (
    <div className="sideBar">
      <p className={`sideBarTitle ${currentMenu === 'ROLE_API_FULL_ACCESS' ? 'sideBarApi' : ''}`}>
        {langValues.settingsVisionConfiguration.sideBar.title}
      </p>
      {currentMenu === 'ROLE_API_FULL_ACCESS'
        ? renderSideBarContent(langValues.settingsVisionConfiguration.sideBar.contentApi)
        : renderSideBarContent(langValues.settingsVisionConfiguration.sideBar.content)}
    </div>
  );

  const renderSideBar = !editing ? renderNormalSideBar : renderEditingSideBar;

  const setAllCheckBoxes = () => {
    let checkedToUpdate = checkedItems.filter((item) => item.orderType !== orderTypeKey);

    if (checkedTypeItems.length < orderTypeItems.length) {
      checkedToUpdate = [...checkedItems.filter((item) => item.orderType !== orderTypeKey), ...orderTypeItems];
    }
    setCheckedItems(checkedToUpdate);
    setNewCheckedRules(checkedToUpdate);
  };

  const passFilteredToCheckedItemsAndRules = (filteredToUpdate, itemToUpdate) => {
    let checkedToUpdate = [...checkedItems];

    filteredToUpdate.forEach((item) => {
      const hasItem = checkedTypeItems.some(
        (element) =>
          element.modelCode === item.modelCode &&
          element.versionCode === item.versionCode &&
          element.modelName === item.modelName &&
          element.versionName === item.versionName
      );
      if (!hasItem) {
        if (item.versionCode === defaultVersionCode) {
          const hasVersions = filteredToUpdate.some(
            (element) => element.modelCode === item.modelCode && element.versionCode !== defaultVersionCode
          );
          if (!hasVersions) {
            checkedToUpdate.push(item);
          }
        } else {
          checkedToUpdate.push(item);
        }
      }
    });

    if (itemToUpdate || filteredToUpdate.length === 0) {
      let uncheckedFiltered = filteredItems;
      if (itemToUpdate) {
        uncheckedFiltered = filteredItems.filter((item) => item.modelCode === itemToUpdate.code);
      }
      uncheckedFiltered.forEach((item) => {
        checkedToUpdate = checkedToUpdate.filter(
          (element) =>
            element.orderType !== orderTypeKey ||
            (element.orderType === orderTypeKey && element.modelCode !== item.modelCode) ||
            (element.orderType === orderTypeKey &&
              element.modelCode === item.modelCode &&
              element.versionCode !== item.versionCode)
        );
      });
    }

    let newCheckedToUpdate = [...checkedToUpdate];

    checkedToUpdate.forEach((item) => {
      const hasModel = newCheckedToUpdate.some(
        (element) => element.modelCode === item.modelCode && element.versionCode === defaultVersionCode
      );
      if (!hasModel) {
        const checkedVersions = filteredToUpdate.filter((element) => element.modelCode === item.modelCode);
        const orderTypeVersions = orderTypeItems.filter((element) => element.modelCode === item.modelCode);
        if (checkedVersions.length === orderTypeVersions.length) {
          newCheckedToUpdate = [
            ...newCheckedToUpdate,
            ...orderTypeItems.filter(
              (element) => element.modelCode === item.modelCode && element.versionCode === defaultVersionCode
            ),
          ];
        }
      }
    });

    setCheckedItems(newCheckedToUpdate);
    setNewCheckedRules(newCheckedToUpdate);
  };

  const setAllFilteredCheckBoxes = () => {
    let filteredToUpdate = [];

    if (filteredCheckedItems.length < filteredItems.length) {
      filteredToUpdate = [...filteredItems];
    }
    setFilteredCheckedItems(filteredToUpdate);
    passFilteredToCheckedItemsAndRules(filteredToUpdate);
  };

  const getListHeaders = () => {
    const listHeaders = [
      {
        label: ' ',
      },
      {
        label: langValues.model,
      },
      {
        label: langValues.code,
      },
      {
        label: langValues.permissions,
        helpText: langValues.settingsVisionConfiguration.permissionsTooltip,
      },
    ];
    if (editing) {
      const isMasterChecked = currentCheckedItems.length === currentItems.length;
      const isMasterIndetermined = currentCheckedItems.length > 0 && !isMasterChecked;

      const firstHeader = {
        label: (
          <Checkbox
            checked={isMasterChecked}
            indeterminate={isMasterIndetermined}
            onChange={() => (filteredItems.length > 0 ? setAllFilteredCheckBoxes() : setAllCheckBoxes())}
          />
        ),
      };

      listHeaders.unshift(firstHeader);
    }
    return getHeadersWithTooltips(listHeaders);
  };

  const setModelCheckBoxes = (item) => {
    let checkedToUpdate = [];
    checkedToUpdate = [
      ...checkedItems.filter(
        (element) =>
          element.orderType !== orderTypeKey || (element.orderType === orderTypeKey && element.modelCode !== item.code)
      ),
    ];

    if (item.isIndeterminate || !item.isChecked) {
      checkedToUpdate = [...checkedToUpdate, ...orderTypeItems.filter((element) => element.modelCode === item.code)];
    }

    setCheckedItems(checkedToUpdate);
    setNewCheckedRules(checkedToUpdate);
  };

  const setFilteredModelCheckBoxes = (item) => {
    let checkedToUpdate = [
      ...filteredCheckedItems.filter(
        (element) =>
          element.orderType !== orderTypeKey || (element.orderType === orderTypeKey && element.modelCode !== item.code)
      ),
    ];

    if (item.isIndeterminate || !item.isChecked) {
      checkedToUpdate = [...checkedToUpdate, ...filteredItems.filter((element) => element.modelCode === item.code)];
      passFilteredToCheckedItemsAndRules(checkedToUpdate);
    } else {
      passFilteredToCheckedItemsAndRules(checkedToUpdate, item);
    }

    setFilteredCheckedItems(checkedToUpdate);
  };

  const setVersionCheckBoxes = (item) => {
    let checkedToUpdate = [];
    const isAllVersionsMarked = () => {
      const allVersions = orderTypeItems.filter(
        (element) =>
          element.orderType === orderTypeKey &&
          element.modelCode === item.parent &&
          element.versionCode !== defaultVersionCode
      );
      const allCheckedVersions = checkedToUpdate.filter(
        (element) =>
          element.orderType === orderTypeKey &&
          element.modelCode === item.parent &&
          element.versionCode !== defaultVersionCode
      );
      return allVersions.length === allCheckedVersions.length;
    };

    if (item.isChecked) {
      checkedToUpdate = [
        ...checkedItems.filter(
          (element) =>
            element.orderType !== orderTypeKey ||
            (element.orderType === orderTypeKey && element.modelCode !== item.parent) ||
            (element.orderType === orderTypeKey &&
              element.modelCode === item.parent &&
              element.versionCode !== item.code &&
              element.versionCode !== defaultVersionCode)
        ),
      ];
    } else {
      checkedToUpdate = [
        ...checkedItems,
        ...orderTypeItems.filter(
          (element) =>
            element.orderType === orderTypeKey && element.modelCode === item.parent && element.versionCode === item.code
        ),
      ];

      if (isAllVersionsMarked()) {
        checkedToUpdate = [
          ...checkedToUpdate,
          ...orderTypeItems.filter(
            (element) =>
              element.orderType === orderTypeKey &&
              element.modelCode === item.parent &&
              element.versionCode === defaultVersionCode
          ),
        ];
      }
    }

    setCheckedItems(checkedToUpdate);
    setNewCheckedRules(checkedToUpdate);
  };

  const setFilteredVersionCheckBoxes = (item) => {
    let checkedToUpdate = [];
    const isAllVersionsMarked = () => {
      const allVersions = filteredItems.filter(
        (element) =>
          element.orderType === orderTypeKey &&
          element.modelCode === item.parent &&
          element.versionCode !== defaultVersionCode
      );
      const allCheckedVersions = checkedToUpdate.filter(
        (element) =>
          element.orderType === orderTypeKey &&
          element.modelCode === item.parent &&
          element.versionCode !== defaultVersionCode
      );
      return allVersions.length === allCheckedVersions.length;
    };

    if (item.isChecked) {
      checkedToUpdate = [
        ...filteredCheckedItems.filter(
          (element) =>
            element.orderType !== orderTypeKey ||
            (element.orderType === orderTypeKey && element.modelCode !== item.parent) ||
            (element.orderType === orderTypeKey &&
              element.modelCode === item.parent &&
              element.versionCode !== item.code &&
              element.versionCode !== defaultVersionCode)
        ),
      ];
    } else {
      checkedToUpdate = [
        ...filteredCheckedItems,
        ...filteredItems.filter(
          (element) =>
            element.orderType === orderTypeKey && element.modelCode === item.parent && element.versionCode === item.code
        ),
      ];

      if (isAllVersionsMarked()) {
        checkedToUpdate = [
          ...checkedToUpdate,
          ...filteredItems.filter(
            (element) =>
              element.orderType === orderTypeKey &&
              element.modelCode === item.parent &&
              element.versionCode === defaultVersionCode
          ),
        ];
      }
    }

    setFilteredCheckedItems(checkedToUpdate);
    setVersionCheckBoxes(item);
  };

  const getListItems = () => {
    const modelRules = currentItems.filter((item) => item.versionCode === defaultVersionCode);

    const modelsWithRules = [];

    const isVersionChecked = (item) => currentCheckedItems.some((element) => element === item);

    let modelVersionRules = [];
    let checkedModelRules = [];
    let isModelChecked = false;
    let isModelIndeterminated = false;
    const setCheckedModels = (modelCode) => {
      modelVersionRules = currentItems.filter((item) => item.modelCode === modelCode);
      checkedModelRules = currentCheckedItems.filter((item) => item.modelCode === modelCode);
      isModelChecked = modelVersionRules.length === checkedModelRules.length;
      isModelIndeterminated = checkedModelRules.length > 0 && !isModelChecked;
    };

    const addChildren = (modelToChange) => {
      const versionsWithRules = [];
      const versionRules = currentItems.filter(
        (item) => item.versionCode !== defaultVersionCode && item.modelCode === modelToChange
      );

      versionRules.forEach((item) => {
        if (versionsWithRules.filter((versions) => versions.code === item.versionCode).length > 0) {
          const versionIndex = versionsWithRules.findIndex((versions) => versions.code === item.versionCode);
          if (item.conditional) {
            versionsWithRules[versionIndex].rules.push(item.rule);
          }
        } else {
          const firstRule = {
            parent: item.modelCode,
            code: item.versionCode,
            name: item.versionName,
            rules: item.conditional ? [item.rule] : [],
            isChecked: isVersionChecked(item),
          };
          versionsWithRules.push(firstRule);
        }
      });
      const currentModelIndex = modelsWithRules.findIndex((models) => models.code === modelToChange);
      versionsWithRules.sort((a, b) => (safeNormalizeString(a.name) > safeNormalizeString(b.name) ? 1 : -1));
      modelsWithRules[currentModelIndex] = {
        ...modelsWithRules[currentModelIndex],
        children: versionsWithRules,
      };
    };

    modelRules.forEach((item) => {
      if (modelsWithRules.filter((models) => models.code === item.modelCode).length > 0) {
        const modelIndex = modelsWithRules.findIndex((models) => models.code === item.modelCode);
        if (item.conditional) {
          modelsWithRules[modelIndex].rules.push(item.rule);
        }
      } else {
        setCheckedModels(item.modelCode);
        const firstRule = {
          code: item.modelCode,
          name: item.modelName,
          rules: item.conditional ? [item.rule] : [],
          isChecked: isModelChecked,
          isIndeterminated: isModelIndeterminated,
          hasChildren:
            orderTypeItems.filter(
              (line) => line.versionCode !== defaultVersionCode && line.modelCode === item.modelCode
            ).length > 0,
        };
        modelsWithRules.push(firstRule);
        addChildren(item.modelCode);
      }
    });
    modelsWithRules.sort((a, b) => (safeNormalizeString(a.name) > safeNormalizeString(b.name) ? 1 : -1));
    return modelsWithRules;
  };

  const renderEmptyState = (
    <div id="emptyState">
      <div id="emptyState-content">
        <EmptyState />
        <span id="emptyState-text">{langValues.noResultsFound}</span>
      </div>
    </div>
  );

  const renderList = () => {
    if (loading) return <SkeletonList items={10} columns={5} />;
    if (error || !rules || rules.length === 0) {
      return <ErrorComponent />;
    }
    if (isFilteredItemsEmpty) {
      return renderEmptyState;
    }

    return (
      <List
        header={getListHeaders()}
        Type={ListVisionConfiguration}
        childProps={{
          listItems: getListItems(),
          currentMenu,
          currentActiveModel,
          setCurrentActiveModel,
          isEditing: editing,
          setModelCheckBoxes: (item) =>
            filteredItems.length > 0 ? setFilteredModelCheckBoxes(item) : setModelCheckBoxes(item),
          setVersionCheckBoxes: (item) =>
            filteredItems.length > 0 ? setFilteredVersionCheckBoxes(item) : setVersionCheckBoxes(item),
        }}
      />
    );
  };

  const getFilteredItems = (typeItems, filtered) => {
    const checkText = (text) => {
      if (text) {
        return safeToUpperCase(text);
      }
      return '';
    };
    const items = typeItems.filter(
      (item) =>
        checkText(item.modelCode.toString()).includes(checkText(searchString)) ||
        checkText(item.versionCode).includes(checkText(searchString)) ||
        checkText(item.modelName).includes(checkText(searchString)) ||
        checkText(item.versionName).includes(checkText(searchString))
    );
    let newFilteredItems = [...items];

    const addVersionsFromModels = () => {
      items
        .filter((item) => item.versionCode === defaultVersionCode)
        .forEach((item) => {
          newFilteredItems = items.filter((element) => element.modelCode !== item.modelCode);
          newFilteredItems = [
            ...newFilteredItems,
            ...typeItems.filter((element) => element.modelCode === item.modelCode),
          ];
        });
    };

    const addModelsFromVersions = () => {
      items
        .filter((item) => item.versionCode !== defaultVersionCode)
        .forEach((item) => {
          const hasModel = newFilteredItems.some(
            (element) => element.modelCode === item.modelCode && element.versionCode === defaultVersionCode
          );
          if (!hasModel) {
            if (!filtered) {
              const modelItems = typeItems.filter(
                (element) => element.modelCode === item.modelCode && element.versionCode === defaultVersionCode
              );
              newFilteredItems = [...newFilteredItems, ...modelItems];
            } else {
              const newFilteredVersions = newFilteredItems.filter(
                (element) => element.modelCode === item.modelCode && element.versionCode !== defaultVersionCode
              );
              const oldFilteredVersions = filtered.filter(
                (element) => element.modelCode === item.modelCode && element.versionCode !== defaultVersionCode
              );
              const hasAllVersions = newFilteredVersions.length === oldFilteredVersions.length;
              if (hasAllVersions) {
                const modelItems = filtered.filter(
                  (element) => element.modelCode === item.modelCode && element.versionCode === defaultVersionCode
                );
                newFilteredItems = [...newFilteredItems, ...modelItems];
              }
            }
          }
        });
    };
    addVersionsFromModels();
    addModelsFromVersions();

    return newFilteredItems;
  };

  const filterItems = () => {
    const filtered = getFilteredItems(orderTypeItems);
    setFilteredItems(filtered);
    if (filtered.length === 0) {
      setIsFilteredItemsEmpty(true);
    } else {
      setIsFilteredItemsEmpty(false);
    }
    const checked = getFilteredItems(checkedTypeItems, filtered);
    setFilteredCheckedItems(checked);
    if (filtered.filter((item) => item.versionCode === defaultVersionCode).length === getNumberOfRules()) {
      setCurrentActiveModel(filtered[0].modelCode);
    } else {
      setCurrentActiveModel(null);
    }
  };

  const clearFilter = () => {
    setFilteredItems([]);
    setIsFilteredItemsEmpty(false);
  };

  const renderSearchInput = (
    <div className="listingBodyHeader">
      <SearchInput
        search={filterItems}
        onChange={(value) => setSearchString(value)}
        searchString={searchString}
        placeholder={langValues.settingsVisionConfiguration.searchInput}
        onClear={clearFilter}
      />
    </div>
  );

  useLayoutEffect(() => {
    if (filteredItems.length > 0) {
      filterItems();
    }
  }, [currentActiveTab]);

  const renderTabs = () => (
    <TabsSelector
      changeActiveTab={(id) => setCurrentActiveTab(id)}
      currentActiveTab={currentActiveTab}
      tabsList={orderTypeList}
    />
  );

  const renderContent = (
    <div className="listingBody">
      {renderTabs()}
      {renderSearchInput}
      <div className="listingWrapper">{renderList()}</div>
    </div>
  );

  const getItemsToSave = () => {
    const itemsToSave = [];
    const filteredCheckedModelVersions = checkedItems.filter((item) =>
      Object.keys(checkedRules).some((rule) => rule === item.rule)
    );
    filteredCheckedModelVersions.forEach((item) => {
      if (
        (checkedRules[item.rule].checked && !checkedRules[item.rule].indeterminate && !item.conditional) ||
        (!checkedRules[item.rule].checked && !checkedRules[item.rule].indeterminate && item.conditional)
      ) {
        const newItem = {
          ...item,
          conditional: !item.conditional,
        };
        itemsToSave.push(newItem);
      }
    });
    return itemsToSave.filter((item) => item.versionCode !== defaultVersionCode);
  };

  const handleNonChanging = () => {
    setEditing(false);
    setCheckedItems([]);
    setFilteredCheckedItems([]);
    setCheckedRules(defaultCheckedRules);
  };

  useLayoutEffect(() => {
    if (editing) {
      handleNonChanging();
    }
    setSearchString('');
    setFilteredItems([]);
    setFilteredCheckedItems([]);
    setCheckedItems([]);
    setCurrentActiveModel(null);
    setIsFilteredItemsEmpty(false);
  }, [currentActiveSubMenu, update.succeed]);

  const renderCancelingButton = (editedRulesExist) => (
    <MaterialButton
      sx={{ ':hover': { borderColor: '#0000003b' } }}
      onClick={editedRulesExist ? () => setCancelModalOpen(true) : () => handleNonChanging()}
      buttonText={langValues.cancel}
      type="secondary"
      variant="outlined"
    />
  );

  const renderSaveButton = (editedRulesExist) => (
    <MaterialButton
      onClick={editedRulesExist ? () => setSaveModalOpen(true) : () => handleNonChanging()}
      buttonText={langValues.save}
      type="primary"
      variant="contained"
    />
  );

  const handleCancelingConfirmation = () => {
    setCancelModalOpen(false);
    handleNonChanging();
  };

  const handleSavingConfirmation = () => {
    const role = subMenuList.find((item) => item.id === currentActiveSubMenu).value;
    const itemsToSave = getItemsToSave();
    updateRules(itemsToSave, role);
    setSaveModalOpen(false);
  };

  const confirmationModals = (
    <div className="confirmationModal">
      <ConfirmationModal
        isOpen={saveModalOpen}
        title={langValues.settingsParametersScreen.saveModalTitle}
        text={langValues.settingsParametersScreen.saveModalText}
        confirmButtonColor={colorVariables.actionPrimaryColor}
        confirmButtonText={langValues.save}
        onClose={() => setSaveModalOpen(false)}
        onConfirm={() => handleSavingConfirmation()}
        onCancel={() => setSaveModalOpen(false)}
      />
      <ConfirmationModal
        isOpen={cancelModalOpen}
        title={langValues.settingsParametersScreen.discardModalTitle}
        text={langValues.settingsParametersScreen.discardModalText}
        confirmButtonColor={colorVariables.errorColor}
        confirmButtonText={langValues.discard}
        onClose={() => setCancelModalOpen(false)}
        onConfirm={() => handleCancelingConfirmation()}
        onCancel={() => setCancelModalOpen(false)}
      />
    </div>
  );

  const snackBars = (
    <div className="snackBars">
      <SnackbarTransactions type="success" isOpen={update.succeed} message={langValues.snackBarLabels.success} />
      <SnackbarTransactions
        type="failure"
        isOpen={update.error}
        message={langValues.snackBarLabels.error}
        onClose={clearUpdateFailure}
        shouldRenderOnCloseButton
      />
    </div>
  );

  const renderEditingButtons = () => {
    if (editing) {
      const editedRulesExist = getItemsToSave().length > 0;
      return (
        <div className="editingButtons">
          {renderCancelingButton(editedRulesExist)}
          {renderSaveButton(editedRulesExist)}
        </div>
      );
    }
    return (
      <MaterialButton
        onClick={() => setEditing(true)}
        buttonText={langValues.edit}
        type="primary"
        variant="contained"
      />
    );
  };

  const renderBodyContent = () => {
    const classNameEditing = editing ? ' editing' : '';
    const localActiveSubMenu = currentActiveSubMenu || 0;

    return (
      <>
        <PageHeader
          title={
            <>
              {langValues.settingsVisionConfiguration.title}
              <div id="dotWraper">
                <Dot color="black" size="14px" />
              </div>
              {subMenuList.find((item) => item.id === localActiveSubMenu).label}
            </>
          }
          subtitle={<UpdateDate />}
          shouldRenderUnregisteredData={User.get().userProfile.includes(roleConsts.admin)}
          RightComponent={() => renderEditingButtons()}
        />
        <div className={`bodyConfiguration${classNameEditing}`}>
          {renderContent}
          {renderSideBar}
        </div>
      </>
    );
  };

  return (
    <div className="visionConfiguration">
      {renderBodyContent()}
      {confirmationModals}
      {snackBars}
    </div>
  );
};

VisionConfiguration.propTypes = {
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  rules: PropTypes.array.isRequired,
  loadRules: PropTypes.func.isRequired,
  setCurrentActiveSubMenu: PropTypes.func.isRequired,
  subMenuList: PropTypes.array.isRequired,
  currentActiveSubMenu: PropTypes.number,
  updateRules: PropTypes.func.isRequired,
  update: PropTypes.object.isRequired,
  clearUpdateFailure: PropTypes.func.isRequired,
};

export default VisionConfiguration;
