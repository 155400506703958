import VisionConfigurationRepository from '../Repositories/VisionConfigurationRepository';

class VisionConfigurationService {
  getRules = async (role) => {
    try {
      const { data } = await VisionConfigurationRepository.getConfigurations(role);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  updateRules = async (itemsToSave) => {
    try {
      console.log('Request body:', itemsToSave);
      await VisionConfigurationRepository.updateVisionConfigurations(itemsToSave);
      return Promise.resolve();
    } catch (err) {
      console.error('Error in updateRules:', err);
      return Promise.reject(err);
    }
  };
}

export default VisionConfigurationService;
