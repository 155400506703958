import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Components/Shared/PageHeader/index';
import langValues from 'Config/i18n/index';
import ListParametersModelsVersions from 'Components/Molecules/ListParametersModelsVersions/ListParametersModelsVersions';
import List from 'Components/Shared/List/List';
import { getHeadersWithTooltips } from 'Components/Molecules/HeadersWithTooltips/HeadersWithTooltips';
import ErrorComponent from 'Components/Atoms/ErrorComponent/ErrorComponent';
import SkeletonList from 'Components/Atoms/SkeletonList/SkeletonList';
import UpdateDate from 'Components/Atoms/UpdateDate/ParametersModelsVersions';
import colorVariables from 'Utils/Styles/colorVariables';
import AddIcon from '@mui/icons-material/Add';
import SnackbarTransactions from 'Components/Atoms/SnackbarTransactions/SnackbarTransactions';
import { roleConsts } from 'Utils/consts';
import { User } from 'Common/Helpers/AuthHelper';
import ConfirmationModal from '../../Molecules/ConfirmationModal/ConfirmationModal';
import MaterialButton from '../../Atoms/MaterialButton/MaterialButton';
import ModelModal from '../../Molecules/ModelModal/ModelModal';
import VersionModal from '../../Molecules/VersionModal/VersionModal';
import './ParametersModelsVersions.scss';

const INITIAL_MODALS_STATE = {
  deleteVersion: {
    isOpen: false,
    versionCode: null,
    modelCode: null,
  },
  deleteModel: {
    isOpen: false,
    modelCode: null,
  },
  putModel: {
    isOpen: false,
    isEdit: false,
    model: {},
  },
  putVersion: {
    isOpen: false,
    isEdit: false,
    version: {},
  },
};

const ParametersModelsVersions = ({
  modelsVersions,
  loadModelsVersions,
  modelsVersionsEdit,
  modelVersionEditFunctions,
}) => {
  const [activeModel, setActiveModel] = React.useState(null);
  const [deleteModelState, setDeleteModelState] = React.useState(INITIAL_MODALS_STATE.deleteModel);
  const [deleteVersionState, setDeleteVersionState] = React.useState(INITIAL_MODALS_STATE.deleteVersion);
  const [putModelState, setPutModelState] = React.useState(INITIAL_MODALS_STATE.putModel);
  const [putVersionState, setPutVersionState] = React.useState(INITIAL_MODALS_STATE.putVersion);
  const [showFillBrandAlert, setShowFillBrandAlert] = React.useState(false);

  React.useEffect(() => {
    loadModelsVersions();
    modelVersionEditFunctions.clear();
  }, []);

  const getItemFromModel = (model, hasChildren) => ({
    name: model.modelName,
    code: model.modelId,
    origin: model.origin,
    brand: model.brand,
    parentCode: null,
    hasChildren,
  });

  const getItemFromVersion = (version) => ({
    name: version.versionName,
    code: version.versionCode,
    origin: null,
    parentCode: version.modelCode,
    hasChildren: false,
  });

  const getListItems = () => {
    const { data } = modelsVersions;

    let listItems = [];
    if (data) {
      const { modelsList, versionsList } = data;

      listItems = modelsList.reduce((acc, model) => {
        const filteredVersionsList = versionsList.filter((version) => version.modelCode === model.modelId);
        const modelHasChildren = filteredVersionsList.length > 0;
        const modelItem = getItemFromModel(model, modelHasChildren);
        let listItemsToAdd = [modelItem];
        if (model.modelId === activeModel) {
          const versionsItems = filteredVersionsList.map((version) => getItemFromVersion(version));
          listItemsToAdd = [...listItemsToAdd, ...versionsItems];
        }
        return acc.concat(listItemsToAdd);
      }, []);
    }
    return listItems;
  };

  const getListHeaders = () => {
    const listHeaders = [
      {
        label: '',
      },
      {
        label: langValues.model,
      },
      {
        label: langValues.code,
      },
      {
        label: langValues.origin,
        helpText: langValues.parametersModelsVersions.originHelpText,
      },
      {
        label: langValues.brand,
      },
      {
        label: '',
      },
    ];

    return getHeadersWithTooltips(listHeaders);
  };

  const handleAddNewVersion = (item) =>
    setPutVersionState({
      isOpen: true,
      isEdit: false,
      version: {
        modelCode: item.code,
      },
    });

  const handleEditVersion = (item) =>
    setPutVersionState({
      isOpen: true,
      isEdit: true,
      version: {
        modelCode: item.parentCode,
        versionCode: item.code,
        versionName: item.name,
      },
    });

  const handleEditModel = (item) =>
    setPutModelState({
      isOpen: true,
      isEdit: true,
      model: {
        modelId: item.code,
        modelName: item.name,
        origin: item.origin,
        brand: item.brand,
      },
    });

  const handleDeleteVersion = (item) =>
    setDeleteVersionState({
      isOpen: true,
      versionCode: item.code,
      modelCode: item.parentCode,
    });

  const handleDeleteModel = (item) =>
    setDeleteModelState({
      isOpen: true,
      modelCode: item.code,
    });

  const renderPageContent = () => (
    <List
      header={getListHeaders()}
      Type={ListParametersModelsVersions}
      childProps={{
        listItems: getListItems(),
        activeModel,
        setActiveModel,
        handleAddNewVersion,
        handleEditVersion,
        handleEditModel,
        handleDeleteVersion,
        handleDeleteModel,
      }}
    />
  );

  const renderSkeleton = () => <SkeletonList items={10} columns={5} />;

  const renderError = () => <ErrorComponent />;

  const renderContent = () => {
    const { loading, error } = modelsVersions;

    if (loading) return renderSkeleton();
    if (error) return renderError();

    return renderPageContent();
  };
  const deleteCacheModels = () =>
    Object.keys(localStorage)
      .filter((x) => x.endsWith('/models'))
      .forEach((x) => localStorage.removeItem(x));

  const renderDeleteModelModal = () => {
    const onConfirm = () => {
      modelVersionEditFunctions.deleteModel(deleteModelState.modelCode);
      setDeleteModelState(INITIAL_MODALS_STATE.deleteModel);
      deleteCacheModels();
    };

    return (
      <ConfirmationModal
        isOpen={deleteModelState.isOpen}
        title={langValues.settingsModelsVersions.deleteModelTitle}
        text={langValues.settingsModelsVersions.deleteModelText}
        confirmButtonColor={colorVariables.errorColor}
        confirmButtonText={langValues.delete}
        onConfirm={onConfirm}
        onClose={() => setDeleteModelState(INITIAL_MODALS_STATE.deleteModel)}
        onCancel={() => setDeleteModelState(INITIAL_MODALS_STATE.deleteModel)}
      />
    );
  };

  const renderDeleteVersionModal = () => {
    const onConfirm = () => {
      modelVersionEditFunctions.deleteVersion(deleteVersionState.versionCode, deleteVersionState.modelCode);
      setDeleteVersionState(INITIAL_MODALS_STATE.deleteVersion);
      deleteCacheModels();
    };

    return (
      <ConfirmationModal
        isOpen={deleteVersionState.isOpen}
        title={langValues.settingsModelsVersions.deleteVersionTitle}
        text={langValues.settingsModelsVersions.deleteVersionText}
        confirmButtonColor={colorVariables.errorColor}
        confirmButtonText={langValues.delete}
        onConfirm={onConfirm}
        onClose={() => setDeleteVersionState(INITIAL_MODALS_STATE.deleteVersion)}
        onCancel={() => setDeleteVersionState(INITIAL_MODALS_STATE.deleteVersion)}
      />
    );
  };

  const renderPutModelModal = () => {
    const onConfirm = () => {
      if (!putModelState.model.brand) {
        return setShowFillBrandAlert(true);
      }
      modelVersionEditFunctions.saveModel(putModelState.model);
      setPutModelState(INITIAL_MODALS_STATE.putModel);
      deleteCacheModels();
      return null;
    };

    const onChangeProperty = (property, value) =>
      setPutModelState({
        ...putModelState,
        model: {
          ...putModelState.model,
          [property]: value,
        },
      });

    const setCode = (newModelId) => onChangeProperty('modelId', newModelId);
    const setName = (newModelName) => onChangeProperty('modelName', newModelName);
    const setOrigin = (newOrigin) => onChangeProperty('origin', newOrigin);
    const setBrand = (newBrand) => onChangeProperty('brand', newBrand);

    return (
      <ModelModal
        isOpen={putModelState.isOpen}
        modalType={putModelState.isEdit ? 'edit' : 'add'}
        cancelModal={() => setPutModelState(INITIAL_MODALS_STATE.putModel)}
        model={{
          name: putModelState.model.modelName,
          code: putModelState.model.modelId,
          origin: putModelState.model.origin,
          brand: putModelState.model.brand,
        }}
        setModel={{
          setName,
          setCode,
          setOrigin,
          setBrand,
        }}
        saveModel={onConfirm}
      />
    );
  };

  const renderPutVersionModal = () => {
    const onConfirm = () => {
      modelVersionEditFunctions.saveVersion(putVersionState.version);
      setPutVersionState(INITIAL_MODALS_STATE.putVersion);
    };

    const onChangeProperty = (property, value) =>
      setPutVersionState({
        ...putVersionState,
        version: {
          ...putVersionState.version,
          [property]: value,
        },
      });

    const setCode = (newVersionId) => onChangeProperty('versionCode', newVersionId);
    const setName = (newVersionName) => onChangeProperty('versionName', newVersionName);

    return (
      <VersionModal
        isOpen={putVersionState.isOpen}
        cancelModal={() => setPutVersionState(INITIAL_MODALS_STATE.putVersion)}
        modalType={putVersionState.isEdit ? 'edit' : 'add'}
        version={{
          name: putVersionState.version.versionName,
          code: putVersionState.version.versionCode,
        }}
        setVersion={{ setName, setCode }}
        saveVersion={onConfirm}
      />
    );
  };

  const renderModals = () => (
    <>
      {renderDeleteModelModal()}
      {renderDeleteVersionModal()}
      {renderPutModelModal()}
      {renderPutVersionModal()}
    </>
  );

  const renderBaseSnackbars = (isSuccessOpen, isErrorOpen, successText, errorText) => (
    <>
      <SnackbarTransactions type="success" isOpen={isSuccessOpen} message={successText} />
      <SnackbarTransactions
        type="failure"
        isOpen={isErrorOpen}
        message={errorText}
        onClose={modelVersionEditFunctions.clear}
        shouldRenderOnCloseButton
      />
    </>
  );
  const renderDeleteModelSnackbars = () =>
    renderBaseSnackbars(
      modelsVersionsEdit.deleteModel.success,
      modelsVersionsEdit.deleteModel.error,
      langValues.settingsModelsVersions.successDeleteModel,
      langValues.settingsModelsVersions.errorDeleteModel
    );

  const renderDeleteVersionSnackbars = () =>
    renderBaseSnackbars(
      modelsVersionsEdit.deleteVersion.success,
      modelsVersionsEdit.deleteVersion.error,
      langValues.settingsModelsVersions.successDeleteVersion,
      langValues.settingsModelsVersions.errorDeleteVersion
    );

  const renderPutModelSnackbars = () =>
    renderBaseSnackbars(
      modelsVersionsEdit.putModel.success,
      modelsVersionsEdit.putModel.error,
      langValues.settingsModelsVersions.successPutModel,
      langValues.settingsModelsVersions.errorPutModel
    );

  const renderPutVersionSnackbars = () =>
    renderBaseSnackbars(
      modelsVersionsEdit.putVersion.success,
      modelsVersionsEdit.putVersion.error,
      langValues.settingsModelsVersions.successPutVersion,
      langValues.settingsModelsVersions.errorPutVersion
    );
  const renderAlertToSelectBrand = () => (
    <SnackbarTransactions
      type="failure"
      isOpen={showFillBrandAlert}
      message={langValues.settingsModelsVersions.fillBrandField}
      onClose={() => setShowFillBrandAlert(false)}
      shouldRenderOnCloseButton
    />
  );

  const renderSnackbars = () => (
    <>
      {renderDeleteModelSnackbars()}
      {renderDeleteVersionSnackbars()}
      {renderPutModelSnackbars()}
      {renderPutVersionSnackbars()}
      {renderAlertToSelectBrand()}
    </>
  );

  const renderAddModelButton = () => {
    if (!User.get().userProfile.includes(roleConsts.admin)) return null;

    const buttonContent = (
      <span className="parametersModelsVersions-addModelButton">
        <AddIcon />
        <p className="font-small3">{langValues.settingsModelsVersions.newModel}</p>
      </span>
    );

    return (
      <MaterialButton
        sx={{ ':hover': { borderColor: '#0000003b' } }}
        onClick={() =>
          setPutModelState({
            isOpen: true,
            isEdit: false,
            model: {},
          })
        }
        buttonText={buttonContent}
        variant="outlined"
      />
    );
  };

  return (
    <section className="parametersModelsVersions">
      <PageHeader
        title={<div className="title">{langValues.settingsModelsVersions.modelsAndVersions}</div>}
        subtitle={<UpdateDate />}
        shouldRenderUnregisteredData={User.get().userProfile.includes(roleConsts.admin)}
        // shouldRenderUnregisteredVersions={User.get().userProfile.includes(roleConsts.admin)}
        RightComponent={() => renderAddModelButton()}
      />
      <article className="parametersModelsVersions-list">{renderContent()}</article>
      {renderModals()}
      {renderSnackbars()}
    </section>
  );
};

ParametersModelsVersions.propTypes = {
  modelsVersions: PropTypes.object.isRequired,
  loadModelsVersions: PropTypes.func.isRequired,
  modelsVersionsEdit: PropTypes.object.isRequired,
  modelVersionEditFunctions: PropTypes.object.isRequired,
};

export default ParametersModelsVersions;
