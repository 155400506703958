import { routeConsts, roleConsts } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

export const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;

export const Token = {
  get: () => JSON.parse(window.localStorage.getItem(TOKEN_KEY)),
  save: ({ token, tokenType }) => {
    const dataString = JSON.stringify({ token, tokenType });
    window.localStorage.setItem(TOKEN_KEY, dataString);
  },
  remove: () => {
    window.localStorage.removeItem(TOKEN_KEY);
  },
};

export const USER_KEY = process.env.REACT_APP_USER_KEY;

export const User = {
  get: () => JSON.parse(window.localStorage.getItem(USER_KEY)),
  save: ({ nome, initials, email, userProfile, dealerId, brand, clientDocument, orderCode }) => {
    const dataString = JSON.stringify({
      nome,
      initials,
      email,
      userProfile,
      dealerId,
      brand,
      clientDocument,
      orderCode,
    });
    window.localStorage.setItem(USER_KEY, dataString);
  },
  remove: () => {
    window.localStorage.removeItem(USER_KEY);
  },
  getRoutePrefix: () => {
    const roles = JSON.parse(window.localStorage.getItem(USER_KEY)).userProfile;
    if (roles.includes(roleConsts.admin)) {
      return routeConsts.admin;
    }
    if (roles.includes(roleConsts.customercare)) {
      return routeConsts.customercare;
    }
    if (roles.includes(roleConsts.dealers)) {
      return routeConsts.dealers;
    }
    if (roles.includes(roleConsts.dealersPSA)) {
      return routeConsts.dealers;
    }
    if (roles.includes(roleConsts.employees)) {
      return routeConsts.employees;
    }
    return routeConsts.default;
  },
  hasProfile: (profile) => {
    const userData = JSON.parse(window.localStorage.getItem(USER_KEY));
    if (userData && userData.userProfile) {
      return userData.userProfile.includes(profile);
    }
    return false;
  },
  hasOneOfProfiles: (profiles) => {
    let hasOne = false;

    if (profiles) {
      profiles.map((profile) => {
        if (User.hasProfile(profile)) hasOne = true;
        return profile;
      });
    } else hasOne = true;

    return hasOne;
  },
  canSeePDA: () => {
    const IS_ADMIN = User.hasProfile('ROLE_ADMIN') && ENV_ENVIRONMENT === 'AR' && User.hasProfile('ROLE_AR');
    const PDA_USER_AR = User.hasProfile('ROLE_PDA') && ENV_ENVIRONMENT === 'AR';

    return IS_ADMIN || PDA_USER_AR;
  },
  canSeeCitroen: () => {
    const ROLE_CITROEN_BR = User.hasProfile(roleConsts.ROLE_BR_XPC) && ENV_ENVIRONMENT === 'BR';
    const ROLE_CITROEN_AR = User.hasProfile(roleConsts.ROLE_AR_XPC) && ENV_ENVIRONMENT === 'AR';

    return ROLE_CITROEN_BR || ROLE_CITROEN_AR;
  },
  canSeePeugeot: () => {
    const ROLE_PEUGEOT_BR = User.hasProfile(roleConsts.ROLE_BR_XPP) && ENV_ENVIRONMENT === 'BR';
    const ROLE_PEUGEOT_AR = User.hasProfile(roleConsts.ROLE_AR_XPP) && ENV_ENVIRONMENT === 'AR';

    return ROLE_PEUGEOT_AR || ROLE_PEUGEOT_BR;
  },
};

export const DEALER_KEY = process.env.REACT_APP_DEALER_KEY;

export const Dealer = {
  get: () => JSON.parse(window.localStorage.getItem(DEALER_KEY)),
  save: ({
    dealerId,
    dealerName,
    dealerToken,
    secondaryDealersIds,
    secondaryDealersNames,
    userDpr,
    regionalId,
    regionalName,
    roleId,
    roleDescription,
    email,
    sectorId,
    sectorName,
  }) => {
    const dataString = JSON.stringify({
      dealerId,
      dealerName,
      dealerToken,
      secondaryDealersIds,
      secondaryDealersNames,
      userDpr,
      regionalId,
      regionalName,
      roleId,
      roleDescription,
      email,
      sectorId,
      sectorName,
    });
    window.localStorage.setItem(DEALER_KEY, dataString);
  },
  remove: () => {
    window.localStorage.removeItem(DEALER_KEY);
  },
};

export default null;
