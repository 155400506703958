import React, { useState, useRef } from 'react';
import { withStyles } from 'tss-react/mui';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { appCode, environment, roleConsts, googleRecaptchaKeys } from 'Utils/consts';
import { ENV_ENVIRONMENT, ENV_SECURITY_LOGIN_URL, ENV_CLIENTID_ID_FED } from 'Common/Helpers/ApiHelper';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { ReCAPTCHA } from 'react-google-recaptcha';

import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';

import './UserEntry.scss';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import {
  userEntryStyles,
  textFieldStyles,
  textFieldInputProps,
  getLoginButtonStyles,
  getLoginButtonStylesConc,
  getLoginButtonStylesConcMarcas,
} from './styles';

const UserEntry = ({
  username,
  password,
  employeeCPF,
  isPSA,
  onLoginClick,
  classes,
  onChange,
  loginError = null,
  loginLoading,
  expiredSession,
  userType,
  radioChange,
}) => {
  const [isRedirectModalOpened, setRedirectModalStatus] = useState(false);
  const [localLoginError, setLocalLoginError] = useState(loginError);

  let isDealer = userType === roleConsts.dealers;
  let isDealerPsa = userType === roleConsts.dealersPSA;
  const isPsa = isPSA === true;
  const recaptchaRef = useRef(null);

  const isEnvAR = ENV_ENVIRONMENT === environment.AR;

  const isDealerRoleOnArEnv =
    environment.AR === ENV_ENVIRONMENT && (userType === roleConsts.dealers || userType === roleConsts.dealersPSA);

  const loadingButton = (loading) => {
    if (!loading) return null;
    return <CircularProgress size={24} className="buttonProgress" />;
  };

  const showLoginError = (error) => {
    if (!error) return null;
    let message = langValues.telaLogin.erroInterno;
    if (isPSA) {
      radioChange(roleConsts.dealers);
    }
    if (userType === roleConsts.customercare) {
      message = langValues.telaLogin.erroResetPassword;

      // Loga o erro no console
      console.error('Erro ao fazer login:', error);
    }
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === 401) {
        message = langValues.telaLogin.erroLoginSenhaIncorretos;
      } else if (status === 403) {
        message = langValues.telaLogin.erroAcessoNegado;
      } else if (status === 404 && userType === roleConsts.employees) {
        message = langValues.telaLogin.cpfNaoEncontrado;
      } else if (status === 404 && userType === roleConsts.client) {
        message = langValues.telaLogin.clientBadRequest;
      } else if (status === 400 && userType === roleConsts.client) {
        message = langValues.telaLogin.googleRecaptcha;
      } else if (status === 406 && userType === roleConsts.dealersPSA) {
        message = langValues.telaLogin.dealerNaoAutorizado;
      }
    }

    return (
      <div className="loginErrorDiv">
        <span id="loginErrorMessage">{message}</span>
      </div>
    );
  };

  // Handler para lidar com mudanças na seleção do RadioButton
  const handleRadioChange = (event) => {
    if (event.target.value !== roleConsts.client) {
      radioChange(event.target.value);
      onChange('', 'email');
      onChange('', 'password');
      setLocalLoginError(null); // Reseta o erro de login
    }
  };

  const renderRadioButton = (name, value, disabledColor) => (
    <FormControlLabel
      value={value}
      classes={{ root: classes.root }}
      control={
        <Radio
          style={{ width: '32px', color: disabledColor }}
          disableRipple
          classes={{
            root: classes.radio,
            checked: classes.checked,
          }}
        />
      }
      label={
        <p className="labelRadioLogin" style={{ color: disabledColor }}>
          {name}
        </p>
      }
    />
  );

  const renderRadioButtons = (isArgentina) => {
    const clientDisabledColor = isArgentina ? '#c5c7d1' : '';
    return (
      <RadioGroup className={classes.group} value={userType} onChange={handleRadioChange}>
        {renderRadioButton(langValues.telaLogin.radioCustomerCare, roleConsts.customercare, '')}
        {!isArgentina && renderRadioButton(langValues.telaLogin.radioDealer, roleConsts.dealers, '')}
        {isArgentina && renderRadioButton(langValues.telaLogin.radioDealer, roleConsts.dealers, '')}
        {/* isArgentina && renderRadioButton(langValues.telaLogin.radioDealer_xPSA, roleConsts.dealersPSA, '') */}
        {/* isArgentina && renderRadioButton(langValues.telaLogin.radioClient,
          roleConsts.client,
          clientDisabledColor) */}
        {!isArgentina && renderRadioButton(langValues.telaLogin.radioEmployee, roleConsts.employees, '')}
      </RadioGroup>
    );
  };

  const renderUserType = () => {
    if (window.location.pathname !== '/login/empregados') {
      return (
        <div className="user-type-form-div">
          <FormControl component="fieldset">{renderRadioButtons(isEnvAR)}</FormControl>
        </div>
      );
    }
    return null;
  };

  const maskTextCUILOrCUIT = (value, past) => {
    const regexReplaceText = /[\D]/g;
    if (value.length >= 14) {
      return past;
    }
    let strCpfEdited = value.replace(regexReplaceText, '');
    if (value.length > past.length) {
      if (strCpfEdited.length >= 10) {
        strCpfEdited = strCpfEdited.replace(/^(\d{2})(\d{8})(\d{0,1})*/, '$1-$2-$3');
      } else if (strCpfEdited.length >= 2) {
        strCpfEdited = strCpfEdited.replace(/^(\d{2})(\d{0,8})*/, '$1-$2');
      }
    } else {
      strCpfEdited = value;
    }
    return strCpfEdited;
  };

  const maskTextCPFOrCNPJ = (value, past) => {
    const regexReplaceText = /[\D]/g;
    if (value.length >= 19) {
      return past;
    }
    let strCpfEdited = value.replace(regexReplaceText, '');
    if (value.length > past.length) {
      if (strCpfEdited.length >= 12) {
        strCpfEdited = strCpfEdited.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})*/, '$1.$2.$3/$4-$5');
      } else if (strCpfEdited.length >= 9) {
        strCpfEdited = strCpfEdited.replace(/^(\d{3})(\d{3})(\d{3})(\d{0,2})*/, '$1.$2.$3-$4');
      } else if (strCpfEdited.length >= 6) {
        strCpfEdited = strCpfEdited.replace(/^(\d{3})(\d{3})(\d{0,3})*/, '$1.$2.$3');
      } else if (strCpfEdited.length >= 3) {
        strCpfEdited = strCpfEdited.replace(/^(\d{3})(\d{0,3})*/, '$1.$2');
      }
    } else {
      strCpfEdited = value;
    }
    return strCpfEdited;
  };

  const maskClientDocument = (value, past) => {
    if (isEnvAR) {
      return maskTextCUILOrCUIT(value, past);
    }
    return maskTextCPFOrCNPJ(value, past);
  };

  const renderTextField = (fieldLabel, fieldType, fieldValue, stateVariable, isCPF) => (
    <div className="login-entry">
      <div className="input-text">
        <TextField
          style={textFieldStyles}
          variant="standard"
          InputProps={textFieldInputProps}
          type={fieldType}
          value={fieldValue}
          label={fieldLabel}
          fullWidth
          onChange={(event) => {
            let finalValue = event.target.value;
            if (isCPF) {
              finalValue = maskClientDocument(finalValue, fieldValue);
            }
            onChange(finalValue, stateVariable);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onLoginClick(recaptchaRef);
            }
          }}
        />
      </div>
    </div>
  );

  const renderUsernameAndPasswordFields = () => {
    if (isDealerRoleOnArEnv) {
      return null;
    }

    const loginUserText = isDealer ? langValues.userEntry.loginDealer : langValues.userEntry.login;
    return (
      <>
        {renderTextField(loginUserText, 'text', username, 'email', false)}
        {renderTextField(langValues.userEntry.password, 'password', password, 'password', false)}
      </>
    );
  };
  const renderUsernameAndOrderFields = () => {
    if (isDealerRoleOnArEnv) {
      return null;
    }
    return (
      <>
        {renderTextField(langValues.userEntry.clientLogin, 'text', username, 'email', true)}
        {renderTextField(langValues.userEntry.clientLoginOrder, 'text', password, 'password', false)}
        <div className="clientRecaptcha">
          <ReCAPTCHA ref={recaptchaRef} sitekey={googleRecaptchaKeys.site} hl={isEnvAR ? 'es-419' : 'pt-BR'} />
        </div>
      </>
    );
  };

  const renderTitle = () => <h3 className="login-titulo">{langValues.loginTexts.welcomeText}</h3>;

  const renderSubTitle = () => {
    let subTitleText = langValues.loginTexts.loginTitle;

    if (expiredSession) {
      subTitleText = langValues.telaLogin.sessaoExpirada;
    } else if (isDealerPsa) {
      subTitleText = langValues.userEntry.dealerCredentialMessage;
    } else if (userType === roleConsts.employees) {
      subTitleText = langValues.userEntry.employeeCredentialMessage;
    } else if (userType === roleConsts.client) {
      subTitleText = langValues.userEntry.clientCredentialMessage;
    } else if (isDealer) {
      subTitleText = langValues.userEntry.dealerCredentialMessage;
    }

    return <h6 className="login-subTitulo">{subTitleText}</h6>;
  };

  const makeBrDealerForgotPasswordURL = () => {
    const { origin, pathname } = window.location;
    const currentURL = origin + pathname;
    return `${ENV_SECURITY_LOGIN_URL}?callback=${currentURL}`;
  };

  const renderRedirectModal = () => {
    if (!isRedirectModalOpened) return null;

    const modalContent = (
      <div className="redirectModalContent">
        <h6>{langValues.userEntry.redirectMessages.title}</h6>
        <span className="font-subtitle2">{langValues.userEntry.redirectMessages.subtitle}</span>
        <Button
          className="button"
          style={getLoginButtonStyles()}
          onClick={() => {
            window.open(makeBrDealerForgotPasswordURL(), '_self');
          }}
        >
          <p className="userEntryButtonText">{langValues.confirm.toUpperCase()}</p>
        </Button>
      </div>
    );

    return (
      <BaseModal open={isRedirectModalOpened} onClose={() => setRedirectModalStatus(false)}>
        {modalContent}
      </BaseModal>
    );
  };

  const renderForgotPassword = () => {
    const text = langValues.userEntry.forgotPassword;
    return (
      <>
        {renderRedirectModal()}
        <button type="button" className="forgotPasswordButton" onClick={() => setRedirectModalStatus(true)}>
          <span className="font-subtitle2">{text}</span>
        </button>
      </>
    );
  };

  const makeArDealerLoginURL = () => {
    const { origin, pathname } = window.location;
    const currentURL = origin + pathname;
    return `https://uprssaml.fiat.com/fiat/Default.aspx?appCode=${appCode}&target=${currentURL}`;
  };

  const makeArDealerLoginURLPSA = () => {
    const { origin, pathname } = window.location;
    const currentURL = origin + pathname;
    const currentURL1 = 'https://easytracking.stellantis.com.ar/login'; // Alterar para variavel de ambiente
    const clientId = 'PDWXSSUJYFRLNZVDDYDLOSIRDIUJUCLI';
    const scope = 'openid%20profile';
    const responseType = 'code&state=110290d2438601809d6baabd018a4b973be408d3';
    return `https://idfed.mpsa.com/as/authorization.oauth2?client_id=${clientId}&redirect_uri=${currentURL1}&scope=${scope}&response_type=${responseType}`;
  };

  const redirectLogin = () => {
    let dealerLoginURL;
    try {
      // Verifica se é um dealer comum ou um dealer PSA
      if (isDealer) {
        dealerLoginURL = makeArDealerLoginURL();
      } else if (isDealerPsa || isPsa) {
        dealerLoginURL = makeArDealerLoginURLPSA();
      }
      window.open(dealerLoginURL, '_self');
    } catch (error) {
      console.error(error); // Log the error for debugging purposes
      window.location.href = '/login';
    }
  };

  const loginFunc = isDealerRoleOnArEnv ? redirectLogin : onLoginClick;

  const renderEntry = () => {
    switch (userType) {
      case roleConsts.employees: {
        if (isEnvAR) {
          return [renderTextField(langValues.userEntry.employeesLogin, 'text', employeeCPF, 'employeeCPF', true)];
        }
        return [
          renderTextField(langValues.userEntry.employeesLogin, 'text', employeeCPF, 'employeeCPF', true),
          renderUsernameAndPasswordFields(),
        ];
      }
      case roleConsts.client: {
        return renderUsernameAndOrderFields();
      }
      default: {
        return renderUsernameAndPasswordFields();
      }
    }
  };

  const getLoginButtonText = () => (
    <p className="userEntryButtonText">
      {userType === roleConsts.client ? langValues.loginTexts.consultButton : langValues.loginTexts.submitButton}
    </p>
  );

  const handleButtonClick = (dealer) => {
    isDealer = dealer === 'fca';
    isDealerPsa = dealer === 'psa';
    loginFunc(recaptchaRef);
  };

  return (
    <div className="login-box">
      {renderTitle()}
      {renderSubTitle()}
      {renderUserType()}
      {renderEntry()}
      {showLoginError(localLoginError)}
      <div className={`loginButtonDiv ${isDealerRoleOnArEnv ? 'button-start' : ''}`}>
        <ToggleEnvironment env={environment.BR}>{isDealer ? renderForgotPassword() : <></>}</ToggleEnvironment>
        {!isDealerRoleOnArEnv ? (
          <Button
            className="button"
            style={getLoginButtonStyles(loginLoading)}
            disabled={loginLoading}
            onClick={() => loginFunc(recaptchaRef)}
            loading={loginLoading.toString()}
          >
            {getLoginButtonText()}
            {loadingButton(loginLoading)}
          </Button>
        ) : (
          <ToggleEnvironment env={environment.AR}>
            <Button
              className="buttons-concessionarias"
              style={getLoginButtonStylesConc(loginLoading)}
              disabled={loginLoading}
              onClick={() => handleButtonClick('fca')}
              loading={loginLoading.toString()}
            >
              <div>
                Acceso
                <div>
                  <span className="label-conc" style={{ fontWeight: 'normal', fontSize: '12px' }}>
                    fiat / jeep / ram
                  </span>
                </div>
              </div>
              {loadingButton(loginLoading)}
            </Button>
            <Button
              className="buttons-concessionarias"
              style={getLoginButtonStylesConc(loginLoading)}
              disabled={loginLoading}
              onClick={() => handleButtonClick('psa')}
              loading={loginLoading.toString()}
            >
              <div>
                Acceso
                <div>
                  <span className="label-conc" style={{ fontWeight: 'normal', fontSize: '12px' }}>
                    peugeot / Citroën / ds
                  </span>
                </div>
              </div>
              {loadingButton(loginLoading)}
            </Button>
          </ToggleEnvironment>
        )}
      </div>
    </div>
  );
};

UserEntry.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isPSA: PropTypes.bool.isRequired,
  employeeCPF: PropTypes.string.isRequired,
  localLoginError: PropTypes.object,
  loginLoading: PropTypes.bool.isRequired,
  expiredSession: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  radioChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(UserEntry, userEntryStyles);
